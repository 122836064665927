import MyProfile from "@/views/user/MyProfile.vue";
import AccountSecurity from "@/views/user/AccountSecurity.vue";
import SubscriptionPage from "@/views/user/SubscriptionPage.vue";
import EmailPreferenceVue from "@/views/user/EmailPreference.vue";

export const profileRoutes = [
  {
    path: "/dashboard/profile",
    name: "My_Profile",

    component: MyProfile,
    meta: {
      name: "My Profile",
      breadcrumbs: ["Dashboard", "My_Profile"],
    },
  },
  {
    path: "/dashboard/profile/security",
    name: "Account_Security",

    component: AccountSecurity,
    meta: {
      name: "Account Security",
      breadcrumbs: ["Dashboard", "Account_Security"],
    },
  },
  {
    path: "/dashboard/profile/subscription",
    name: "My Subscription",
    component: SubscriptionPage,
    meta: {
      name: "My Subscription",
      breadcrumbs: ["Dashboard", "My Subscription"],
    },
  },
  {
    path: "/dashboard/profile/email-preferences",
    name: "Email Preferences",
    component: EmailPreferenceVue,
    meta: {
      name: "Email Preferences",
      breadcrumbs: ["Dashboard", "Email Preferences"],
    },
  },
];
