import { PaginationMeta, Meta, User } from "@/types";
import { EventDateResponse } from "@/types";
import { axiosInstance } from "../clients";

async function listEvents(data: {
  page: number | string;
  type: string;
  query: string;
  status: string;
  trainer: string;
  location: string;
  timeframe: string;
}): Promise<{ data: { events: EventDateResponse[], cities: string[], trainers: User[] }; meta: PaginationMeta }> {
  return (await axiosInstance.get(`/v1/event-dates`, { withCredentials: true, params: data })).data;
}

async function getEvent(id: string): Promise<{ data: { event: EventDateResponse }; meta: Meta }> {
  return (await axiosInstance.get(`/v1/event-dates/${id}`, { withCredentials: true })).data;
}

async function updateEvent(id: string, data: any): Promise<{ data: { event: EventDateResponse }; meta: Meta }> {
  return (await axiosInstance.post(`/v1/event-dates/${id}`, data, { withCredentials: true })).data;
}

export default {
  listEvents,
  getEvent,
  updateEvent,
};
