import { Meta, Level, Template, CourseType, PaginationMeta } from "@/types";
import { axiosInstance } from "../clients";

async function listTemplates(
  page: string,
  params?: {
    type?: string;
    search?: string;
    events?: boolean;
  }
): Promise<{ data: { templates: Template[] }; meta: PaginationMeta }> {
  return (
    await axiosInstance.get(`/v1/templates`, {
      withCredentials: true,
      params: {
        ...params,
        page,
      },
    })
  ).data;
}

async function listTemplateTypes(): Promise<{ data: { types: CourseType[] } }> {
  return (await axiosInstance.get(`/v1/public/templates/types`, { withCredentials: true })).data;
}

async function listTemplateLevels(): Promise<{ data: { levels: Level[] } }> {
  return (await axiosInstance.get(`/v1/public/templates/levels`, { withCredentials: true })).data;
}

async function getTemplate(id: number, options: { events: Boolean }): Promise<{ data: { template: Template; story: { [key: string]: any } }; meta: Meta }> {
  if (options.events) {
    return (await axiosInstance.get(`/v1/templates/${id}?events=true`, { withCredentials: true })).data;
  }

  return (await axiosInstance.get(`/v1/templates/${id}`, { withCredentials: true })).data;
}

async function getTemplateStory(id: number, slug: string): Promise<{ data: { story: any } }> {
  return (await axiosInstance.get(`/v1/public/templates/${id}/story?slug=${slug}`, { withCredentials: true })).data;
}

async function listMoodleCourses(): Promise<{ data: { courses: any } }> {
  return (await axiosInstance.get(`/v1/moodle/courses`, { withCredentials: true })).data;
}

async function getMoodleCourse(id: number): Promise<{ data: { course: any } }> {
  return (await axiosInstance.get(`/v1/moodle/courses/${id}`, { withCredentials: true })).data;
}

async function createTemplate(data: any): Promise<{ data: { template: Template }; meta: Meta }> {
  return (await axiosInstance.post("/v1/templates", data, { withCredentials: true })).data;
}

async function updateTemplate(id: number, data: any): Promise<{ data: { template: Template }; meta: Meta }> {
  return (await axiosInstance.post(`/v1/templates/${id}`, data, { withCredentials: true })).data;
}

async function updateStatus(id: number, data: { status: string }): Promise<{ data: { template: Template }; meta: Meta }> {
  return (await axiosInstance.post(`/v1/templates/${id}/status`, data, { withCredentials: true })).data;
}

async function deleteTemplate(id: number): Promise<{ data: { deleted: boolean }; meta: Meta }> {
  return (await axiosInstance.delete(`/v1/templates/${id}`, { withCredentials: true })).data;
}

export default {
  listTemplates,
  listTemplateTypes,
  listTemplateLevels,
  getTemplate,
  getTemplateStory,
  createTemplate,
  updateTemplate,
  updateStatus,
  deleteTemplate,
  listMoodleCourses,
  getMoodleCourse,
};
