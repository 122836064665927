import { Meta } from "@/types";
import { UpdateProfileRequest, User } from "@/types";
import { axiosInstance, publicAxiosInstance } from "../clients";

async function getLoginUrl(ref?: string, locale?: string) {
  if (ref) {
    return (await publicAxiosInstance.get(`/v1/auth/login?ref=${ref}&locale=${locale}`, { withCredentials: true })).data;
  }

  return (await publicAxiosInstance.get("/v1/auth/login", { withCredentials: true })).data;
}

async function getSignupUrl(ref?: string) {
  if (ref) {
    return (await publicAxiosInstance.get(`/v1/auth/getSignupUrl?ref=${ref}`, { withCredentials: true })).data;
  }

  return (await publicAxiosInstance.get("/v1/auth/getSignupUrl", { withCredentials: true })).data;
}

async function getSignup(id_token: string, state?: string) {
  const queryParams: { id_token: string; state?: string } = {
    id_token: id_token,
  };

  if (state) queryParams["state"] = state;

  return await publicAxiosInstance.get(`/v1/auth/signup`, { withCredentials: true, params: queryParams });
}

async function getLogoutUrl() {
  return (await publicAxiosInstance.get("/v1/auth/logout", { withCredentials: true })).data;
}

async function getProfile() {
  return (await axiosInstance.get("/v1/auth/me", { withCredentials: true })).data;
}

async function updateProfile(data: UpdateProfileRequest): Promise<{ data: { user: User; logout: boolean }; meta: Meta }> {
  return (await axiosInstance.post(`/v1/auth/me`, data, { withCredentials: true })).data;
}

async function deleteProfile(): Promise<{ data: { user: User }; meta: Meta }> {
  return (await axiosInstance.delete(`/v1/auth/me`, { withCredentials: true })).data;
}

export default {
  getLoginUrl,
  getSignupUrl,
  getSignup,
  getProfile,
  updateProfile,
  deleteProfile,
  getLogoutUrl,
};
