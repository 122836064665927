import { useGlobalStore } from "@/store";
import axios from "axios";

const baseUrl = process.env.VUE_APP_BACKEND_URL;

let hasBeenDisabled = false;

export const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

export const publicAxiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // TODO: Better Error Handling
    if (error.response!.status >= 400) {
      const globalStore = useGlobalStore();

      const errors = error.response;

      console.log("Error", errors);

      if (errors === "Your account has been disabled. Please contact your administrator.") {
        console.log("User is disabled");
        clearCookies();

        if (!hasBeenDisabled) {
          globalStore.toast("ERROR", "Your account has been disabled. Please contact support.");
          hasBeenDisabled = true;
        }

        return;
      }

      /** If an access token exists && user was on the dashboard, force them to relog */
      if (window.location.href.includes("dashboard") && error.response.status === 400 && error.response && error.response.request.responseURL.includes("/auth/me")) {
        const redirect_url = window.location.href.split("?")[0];
        console.log("Reauthenticating", redirect_url);
        window.localStorage.setItem("redirect_url", redirect_url);
        return (window.location.href = `/login`);
      }

      if (typeof errors === "string") {
        return globalStore.toast("ERROR", errors);
      }

      if (errors && errors.length > 0) {
        for (let index = 0; index < errors.length; index++) {
          const error = errors[index];

          globalStore.toast("ERROR", error.message ? error.message : error);
        }
      }
    }
  }
);

export function clearCookies() {
  const cookies = document.cookie.split("; ");
  for (let c = 0; c < cookies.length; c++) {
    const d = window.location.hostname.split(".");
    while (d.length > 0) {
      const cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" + d.join(".") + " ;path=";
      const p = location.pathname.split("/");
      document.cookie = cookieBase + "/";
      while (p.length > 0) {
        document.cookie = cookieBase + p.join("/");
        p.pop();
      }
      d.shift();
    }
  }
}

export function getCookie(cname: string) {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
