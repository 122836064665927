import { axiosInstance } from "../clients";

import { Meta } from "@/types";

async function getUsersReports() {
  return (await axiosInstance.get(`/v1/reports/users`)).data;
}

async function getSubsReports() {
  return (await axiosInstance.get(`/v1/reports/subscriptions`)).data;
}

async function getSalesReport() {
  return (await axiosInstance.get(`/v1/reports/sales`)).data;
}

async function getOrgsReport() {
  return (await axiosInstance.get(`/v1/reports/organisations`)).data;
}

async function exportBrickClubData(data: { start: string; end: string }): Promise<{ meta: Meta, data: { url: string } }> {
  return (await axiosInstance.post("/v1/reports/brick-clubs", data)).data;
}

export default {
  getUsersReports,
  getSubsReports,
  getSalesReport,
  getOrgsReport,
  exportBrickClubData
};
