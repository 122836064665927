import { Transaction, PaginationMeta, User, License, Booking } from "@/types";
import { axiosInstance } from "../clients";

async function listSubscriptions(data: {
  page: string | number;
  method?: string;
  status?: string;
  user_id?: string;
  sort?: string;
  dir?: string;
}): Promise<{ meta: PaginationMeta; data: { subscriptions: User[]; users: User[] } }> {
  return (await axiosInstance.get(`/v1/subscriptions`, { params: data })).data;
}

async function getSubscription(id: string): Promise<{ meta: PaginationMeta; data: { subscription: Transaction; license?: License; booking?: Booking } }> {
  return (await axiosInstance.get(`/v1/subscriptions/invoice/${id}`)).data;
}

async function markSubscriptionInvoiceAsPaid(id: string): Promise<{ meta: PaginationMeta; data: { transaction: Transaction } }> {
  return (await axiosInstance.post(`/v1/subscriptions/invoice/paid/${id}`)).data;
}

async function markSubscriptionInvoiceIssued(id: string): Promise<{ meta: PaginationMeta; data: { transaction: Transaction } }> {
  return (await axiosInstance.post(`/v1/subscriptions/invoice/issued/${id}`)).data;
}

export default { listSubscriptions, markSubscriptionInvoiceAsPaid, markSubscriptionInvoiceIssued, getSubscription };
