import OrdersList from "@/views/orders/OrdersList.vue";
import LicenseView from "@/views/LicenseView.vue";
import SubscriptionOrderView from "@/views/SubscriptionOrderView.vue";

export const orderRoutes = [
  {
    path: "/dashboard/orders",
    name: "Orders",
    component: OrdersList,
    meta: {
      name: "Orders",
      breadcrumbs: ["Dashboard", "Orders"],
    },
  },
  {
    path: "/dashboard/licenses",
    name: "Licenses",
    component: OrdersList,
    meta: {
      name: "Licenses",
      breadcrumbs: ["Dashboard", "Licenses"],
    },
  },
  {
    path: "/dashboard/licenses/:oid/:lid",
    name: "License",
    component: LicenseView,
    meta: {
      name: "License",
      breadcrumbs: ["Dashboard", "Licenses", "License"],
    },
  },
  {
    path: "/dashboard/subscriptions",
    name: "Subscriptions",
    component: OrdersList,
    meta: {
      name: "Subscriptions",
      breadcrumbs: ["Dashboard", "Subscriptions"],
    },
  },
  {
    path: "/dashboard/orders/subscriptions/:id",
    name: "Subscription Order",
    component: SubscriptionOrderView,
    meta: {
      name: "Subscription Order",
      breadcrumbs: ["Dashboard", "Subscriptions", "Subscription Order"],
    },
  },
];
