import { PriceSummary, ProductTaxCalculation, Transaction } from "@/types";

export function formatPrice(price: number) {
  if (String(price).includes(".")) {
    return price;
  }

  const newPrice = String(price).substring(0, String(price).length - 2) + "." + String(price).substring(String(price).length - 2);

  return Number(newPrice);
}

export function intlFormat(value: number, currency?: string) {
  return new Intl.NumberFormat("en-GB", { style: "currency", currency: currency ? currency : "GBP" }).format(value);
}

export function getPriceSummary(transaction: any): PriceSummary {
  let rrpPricing: ProductTaxCalculation[] = [];
  let discountedPricing: ProductTaxCalculation[] = [];

  let number_tax_amount = 0;
  let number_gross_amount = 0;
  let number_net_amount = 0;
  let number_course_net_amount = 0;
  let number_discount_amount = 0;
  let number_subscription_amount = 0;
  let number_tax_percent = 0;
  let number_course_quantity = 0;
  let number_subscription_quantity = 0;
  let billing_country = "united kingdom";

  if (!transaction) {
    return {
      CourseQuantity: 0,
      SubscriptionQuantity: 0,
      TotalVAT: 0,
      TotalGrossPrice: 0,
      TotalNetPriceBeforeDiscounts: 0,
      TotalDiscount: 0,
      TotalCourseNetPriceBeforeDiscounts: 0,
      TotalSubscriptionNetPriceBeforeDiscounts: 0,
      VATPercent: 0,
      PriceRRP: [],
      PriceDiscounted: [],
      UserBillingCountry: "unknown",
    };
  }

  if (!transaction.pricing_rrp) {
    return {
      CourseQuantity: 0,
      SubscriptionQuantity: 0,
      TotalVAT: 0,
      TotalGrossPrice: 0,
      TotalNetPriceBeforeDiscounts: 0,
      TotalDiscount: 0,
      TotalCourseNetPriceBeforeDiscounts: 0,
      TotalSubscriptionNetPriceBeforeDiscounts: 0,
      VATPercent: 0,
      PriceRRP: [],
      PriceDiscounted: [],
      UserBillingCountry: "unknown",
    };
  }

  if (transaction.pricing_rrp) {
    const rrpPricingJsArray = JSON.stringify(transaction.pricing_rrp);
    const jsRrpPricing = JSON.parse(rrpPricingJsArray);
    rrpPricing = jsRrpPricing[0];

    number_course_quantity = Number(rrpPricing[0]["Quantity"]);
    billing_country = rrpPricing[0]["UserBillingCountry"] ? rrpPricing[0]["UserBillingCountry"] : "united kingdom";

    number_net_amount = rrpPricing.length ? Number(rrpPricing[0]["TotalNetPrice"]) : 0;
    number_course_net_amount = rrpPricing.length ? Number(rrpPricing[0]["TotalNetPrice"]) : 0;
    number_tax_amount = rrpPricing.length ? Number(rrpPricing[0]["TotalVAT"]) : 0;
    number_gross_amount = rrpPricing.length ? Number(rrpPricing[0]["TotalGrossPrice"]) : 0;

    if (rrpPricing.length > 1) {
      number_subscription_quantity = Number(rrpPricing[1]["Quantity"]);
      number_subscription_amount = Number(rrpPricing[1]["TotalNetPrice"]);
      number_net_amount = number_net_amount + Number(rrpPricing[1]["TotalNetPrice"]);
      number_tax_amount = number_tax_amount + Number(rrpPricing[1]["TotalVAT"]);
      number_gross_amount = number_gross_amount + Number(rrpPricing[1]["TotalGrossPrice"]);
    }
  }

  if (transaction.pricing_discounted) {
    const discountedPricingJsArray = JSON.stringify(transaction.pricing_discounted);
    const jsDiscountedPricing = JSON.parse(discountedPricingJsArray);
    discountedPricing = jsDiscountedPricing[0];

    number_discount_amount = number_net_amount - Number(discountedPricing[0]["DiscountedTotalNetPrice"]);
    // number_net_amount = Number(discountedPricing[0]["TotalNetPrice"]);
    number_tax_amount = Number(discountedPricing[0]["DiscountedTotalVAT"]);
    number_gross_amount = Number(discountedPricing[0]["DiscountedTotalGrossPrice"]);

    if (discountedPricing.length > 1) {
      number_discount_amount = number_net_amount - (Number(discountedPricing[0]["DiscountedTotalNetPrice"]) + Number(discountedPricing[1]["DiscountedTotalNetPrice"]));

      // number_net_amount = number_net_amount + Number(discountedPricing[1]["TotalNetPrice"]);
      number_tax_amount = number_tax_amount + Number(discountedPricing[1]["DiscountedTotalVAT"]);
      number_gross_amount = number_gross_amount + Number(discountedPricing[1]["DiscountedTotalGrossPrice"]);
    }
  }

  number_tax_percent = rrpPricing.length ? Number(rrpPricing[0]["VATPercent"]) : 0;

  return {
    CourseQuantity: number_course_quantity,
    SubscriptionQuantity: number_subscription_quantity,
    TotalVAT: number_tax_amount,
    TotalGrossPrice: number_gross_amount,
    TotalNetPriceBeforeDiscounts: number_net_amount,
    TotalDiscount: number_discount_amount,
    TotalCourseNetPriceBeforeDiscounts: number_course_net_amount,
    TotalSubscriptionNetPriceBeforeDiscounts: number_subscription_amount,
    VATPercent: number_tax_percent,
    PriceRRP: rrpPricing,
    PriceDiscounted: discountedPricing,
    UserBillingCountry: billing_country,
  };
}
