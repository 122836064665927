import { PaginationMeta, Meta, Booking, User, Transaction } from "@/types";
import { axiosInstance } from "../clients";

async function listRecentOrders(): Promise<{ data: { bookings: Booking[] }; meta: PaginationMeta }> {
  return (await axiosInstance.get(`/v1/admin/bookings`, { withCredentials: true })).data;
}

async function listOwnBookings(data: { page?: number | string; include_added_by_user?: boolean }): Promise<{ data: { bookings: Booking[] }; meta: PaginationMeta }> {
  return (await axiosInstance.get(`/v1/user-bookings`, { withCredentials: true, params: data })).data;
}

async function listBookings(data: {
  page?: number | string;
  event_id?: string | number;
  user_id?: string | null;
  organisation_id?: string | null;
  active?: boolean;
  platform?: string;
  status?: string;
  region?: string;
  user_attended?: boolean | null;
  is_delegate?: boolean | null;
  added_by_user?: boolean;
  play_event?: boolean;
}): Promise<{ data: { bookings: Booking[]; platforms: string[]; organisations: string[]; events: number[], users: User[] }; meta: PaginationMeta }> {
  return (await axiosInstance.get(`/v1/bookings`, { withCredentials: true, params: data })).data;
}

async function listCompletedBookings(user_id: string) {
  return (
    await axiosInstance.get(`/v1/bookings`, {
      withCredentials: true,
      params: {
        user_id: user_id,
        user_attended: true,
      },
    })
  ).data;
}

async function getBooking(id: string): Promise<{
  data: {
    booking: Booking;
    group: {
      user: User;
      size: number;
      bookings: Booking[];
    };
    courses: Booking[];
    transaction_group: Transaction[];
  };
  meta: Meta;
}> {
  return (await axiosInstance.get(`/v1/bookings/${id}`, { withCredentials: true })).data;
}

async function createManualBooking(data: { user_id: string; event_id: number }): Promise<{ data: { booking: Booking }; meta: Meta }> {
  return (await axiosInstance.post(`/v1/bookings/manual`, data, { withCredentials: true })).data;
}

async function redeemLicense(data: { user_id: string; event_id: number; organisation?: string }): Promise<{ data: { booking: Booking }; meta: Meta }> {
  return (await axiosInstance.post(`/v1/bookings/license`, data, { withCredentials: true })).data;
}

async function updateBookingAttendance(id: number, data: any): Promise<{ data: { booking: Booking }; meta: Meta }> {
  return (await axiosInstance.post(`/v1/bookings/${id}/attendance`, data, { withCredentials: true })).data;
}

async function updateBookingPayment(id: number, data: any): Promise<{ data: { booking: Booking }; meta: Meta }> {
  return (await axiosInstance.post(`/v1/bookings/${id}/payment`, data, { withCredentials: true })).data;
}

async function updateRefunded(id: number, data: any): Promise<{ data: { booking: Booking }; meta: Meta }> {
  return (await axiosInstance.post(`/v1/bookings/${id}/refunded`, data, { withCredentials: true })).data;
}

async function updateBookingPrerequisites(id: number, data: any): Promise<{ data: { booking: Booking }; meta: Meta }> {
  return (await axiosInstance.post(`/v1/bookings/${id}/prerequisites`, data, { withCredentials: true })).data;
}

async function toggleBookingInvoiceIssued(id: number): Promise<{ data: { booking: Booking; bookings?: Booking[] }; meta: Meta }> {
  return (await axiosInstance.post(`/v1/bookings/${id}/invoice`, {}, { withCredentials: true })).data;
}

async function removeAttendee(id: number): Promise<{ data: { booking: Booking }; meta: Meta }> {
  return (await axiosInstance.post(`/v1/bookings/${id}/remove`, { id }, { withCredentials: true })).data;
}

async function listTransactions(data: { page: string | number; sort?: string; dir?: string; user_id?: string }): Promise<{ meta: PaginationMeta; data: { transactions: any[], users: User[] } }> {
  return (await axiosInstance.get(`/v1/transactions/oneOffFee`, { params: data })).data;
}

export default {
  listBookings,
  listCompletedBookings,
  getBooking,
  updateRefunded,
  updateBookingAttendance,
  updateBookingPayment,
  updateBookingPrerequisites,
  listRecentOrders,
  removeAttendee,
  createManualBooking,
  redeemLicense,
  toggleBookingInvoiceIssued,
  listTransactions,
  listOwnBookings,
};
