import { PaginationMeta, Meta, CreateUserRequest, User, Role, Note, File, Booking, Event, Club, Transaction } from "@/types";
import { axiosInstance } from "../clients";

async function listUsers(data: {
  page?: number | string;
  role?: string | null;
  roles?: string[] | null;
  search?: string;
  sort?: string;
  dir?: string;
  sub_status?: string;
  region?: string;
}): Promise<{ data: { users: User[] }; meta: PaginationMeta }> {
  return (await axiosInstance.get(`/v1/users`, { withCredentials: true, params: data })).data;
}

async function listUsersByRole(): Promise<{ data: { [key: string]: Role }; meta: PaginationMeta }> {
  return (await axiosInstance.get("/v1/user-groups", { withCredentials: true })).data;
}

async function getUser(id: string): Promise<{ data: { user: User }; meta: Meta }> {
  return (await axiosInstance.get(`/v1/users/${id}`, { withCredentials: true })).data;
}

async function createUser(data: CreateUserRequest): Promise<any> {
  return (await axiosInstance.post("/v1/users", data, { withCredentials: true })).data;
}

async function addUserToService(service: string, id: string): Promise<{ data: { user: User }; meta: Meta }> {
  return (
    await axiosInstance.post(
      `/v1/users/${id}/${service}/add`,
      {
        id,
      },
      { withCredentials: true }
    )
  ).data;
}

async function updateUser(id: string, data: any): Promise<{ data: { user: User }; meta: Meta }> {
  return (await axiosInstance.post(`/v1/users/${id}`, data, { withCredentials: true })).data;
}

async function updateUserRole(id: string, role_id: Role["id"]): Promise<{ data: { user: User }; meta: Meta }> {
  return (await axiosInstance.post(`/v1/users/${id}/roles/${role_id}`, { withCredentials: true })).data;
}

async function updateDevRole(id: string, role_id: Role["id"]): Promise<{ data: { user: User }; meta: Meta }> {
  return (await axiosInstance.post(`/v1/users/${id}/roles/${role_id}/dev`, { withCredentials: true })).data;
}

async function getCoursesByUser(
  id: string,
  sort?: string,
  dir?: string
): Promise<{ data: { courses: Booking[]; initiate: Booking; facilitator: Booking; trainer: Booking }; meta: Meta }> {
  return (await axiosInstance.get(`/v1/users/${id}/courses`, { withCredentials: true, params: { sort, dir } })).data;
}

async function getEventsByUser(id: string): Promise<{ data: { events: Event[] }; meta: Meta }> {
  return (await axiosInstance.get(`/v1/users/${id}/events`, { withCredentials: true })).data;
}

async function getClubsByUser(id: string): Promise<{ data: { clubs: Club[] }; meta: Meta }> {
  return (await axiosInstance.get(`/v1/users/${id}/clubs`, { withCredentials: true })).data;
}

async function getBookingsByUser(id: string): Promise<{ data: { bookings: Booking[] }; meta: Meta }> {
  return (await axiosInstance.get(`/v1/users/${id}/bookings`, { withCredentials: true })).data;
}

async function getTransactionsByUser(id: string): Promise<{ data: { transactions: Transaction[] }; meta: Meta }> {
  return (await axiosInstance.get(`/v1/users/${id}/transactions`, { withCredentials: true })).data;
}

async function updateUserLanguage(id: string, data: { language: string }): Promise<{ data: { user: User }; meta: Meta }> {
  return (await axiosInstance.post(`/v1/users/${id}/language`, data, { withCredentials: true })).data;
}

async function toggleUserAccess(id: string): Promise<{ data: { user: User }; meta: Meta }> {
  return (await axiosInstance.post(`/v1/users/${id}/access`, { withCredentials: true })).data;
}

async function deleteUser(id: string): Promise<{ data: { deleted: boolean }; meta: Meta }> {
  return (await axiosInstance.delete(`/v1/users/${id}`, { withCredentials: true })).data;
}

async function listRoles(): Promise<{ data: { roles: Role[] }; meta: Meta }> {
  return (await axiosInstance.get(`/v1/roles`, { withCredentials: true })).data;
}

async function createUserNote(id: string, data: { content: string }): Promise<{ data: { note: any }; meta: Meta }> {
  return (await axiosInstance.post(`/v1/users/${id}/notes`, data, { withCredentials: true })).data;
}

async function listUserNotes(id: string): Promise<{ data: { notes: Note[] }; meta: Meta }> {
  return (await axiosInstance.get(`/v1/users/${id}/notes`, { withCredentials: true })).data;
}

async function deleteUserNote(id: string, note_id: number): Promise<{ data: { deleted: boolean }; meta: Meta }> {
  return (await axiosInstance.delete(`/v1/users/${id}/notes/${note_id}`, { withCredentials: true })).data;
}

async function listUserFiles(id: string): Promise<{ data: { files: File[] }; meta: Meta }> {
  return (await axiosInstance.get(`/v1/users/${id}/files`, { withCredentials: true })).data;
}

async function uploadUserFile(id: string, data: any): Promise<{ data: { id: number; path: string; name: string; size: number }; meta: Meta }> {
  return (
    await axiosInstance.post(`/v1/users/${id}/files`, data, {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  ).data;
}

async function deleteUserFile(id: string, file_id: number): Promise<{ data: { deleted: true }; meta: Meta }> {
  return (await axiosInstance.delete(`/v1/users/${id}/files/${file_id}`, { withCredentials: true })).data;
}

async function sendPasswordReset(id: String): Promise<{ data: { user: User }; meta: Meta }> {
  return (await axiosInstance.post(`/v1/users/${id}/password-reset`, { withCredentials: true })).data;
}

async function toggleTwoCheckoutAutoRenew(user: User): Promise<{ data: { auto_renew: boolean | string }; meta: Meta }> {
  return await axiosInstance.post("/v1/twocheckout/autorenew", { playUserId: user.id, autoRenew: !user.auto_renew });
}

async function toggleStripeAutoRenew(user: User): Promise<{ data: { auto_renew: boolean | string }; meta: Meta }> {
  return await axiosInstance.post("/v1/stripe/autorenew", { playUserId: user.id, autoRenew: !user.auto_renew });
}

async function manualSubscribeUser(user: User) {
  return await axiosInstance.get(`/v1/subscriptions/subscribe/${user.id}`);
}

async function checkValidDelegate(event_id: Event["id"], user: any): Promise<{ data: { error?: string; valid: boolean; user?: User }; meta: Meta }> {
  return await axiosInstance.post("/v1/bookings/delegate/license-validate", {
    user,
    event_id,
  });
}

async function onboard(role: string) {
  return await axiosInstance.post("/v1/onboard", { role }, { withCredentials: true });
}

async function checkUserHasEventBooking(user_id: string, event_id: number): Promise<{ data: { has_booking: false }; meta: Meta }> {
  return (await axiosInstance.get(`/v1/users/${user_id}/bookings/${event_id}`, { withCredentials: true })).data;
}

/** TODO: Add endpoint to batch delete multiple users */
async function deleteMultipleUsers(ids: string[]) {
  return (await axiosInstance.delete(`/v1/users`, { withCredentials: true })).data;
}

export default {
  listUsers,
  getUser,
  listRoles,
  createUser,
  updateUser,
  updateUserRole,
  updateDevRole,
  updateUserLanguage,
  deleteUser,
  listUsersByRole,
  createUserNote,
  listUserNotes,
  deleteUserNote,
  listUserFiles,
  uploadUserFile,
  toggleUserAccess,
  deleteUserFile,
  sendPasswordReset,
  toggleStripeAutoRenew,
  toggleTwoCheckoutAutoRenew,
  manualSubscribeUser,
  getCoursesByUser,
  getEventsByUser,
  getClubsByUser,
  checkValidDelegate,
  onboard,
  getBookingsByUser,
  getTransactionsByUser,
  addUserToService,
  checkUserHasEventBooking,
};
