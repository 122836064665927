<template>
  <div class="flex items-center justify-center p-6 py-16 text-center">
    <div>
      <h1 class="text-xl">Registration Error</h1>

      <div class="mt-4">
        <p>There was an error during account creation. Please try again or contact support if the issue persists.</p>
        <p>This could be due to submitting no first or last name during the signup process.</p>
      </div>

      <div class="mt-4 text-blue-500 underline">
        <a href="https://playincluded.com">Back to Home</a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>
