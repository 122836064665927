import { Country, Language } from "@/types";
import { axiosInstance } from "../clients";

async function listCountries(): Promise<{ countries: Country[] }> {
  return (await axiosInstance.get(`/v1/public/countries`)).data;
}

async function listLanguages(): Promise<{ languages: string[] }> {
  return (await axiosInstance.get(`/v1/public/languages`)).data;
}

async function listServicePortalLanguages(): Promise<{ languages: Language[] }> {
  return (await axiosInstance.get(`/v1/languages`)).data;
}

export default { listCountries, listLanguages, listServicePortalLanguages };
