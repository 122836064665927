import { Role } from "@/types";

export function getUsersHighestRole(roles: Role[]) {
  return roles.reduce((prev, next) => {
    return prev.order > next.order ? prev : next;
  });
}

export function userHasRole(user_roles: Role[], required_roles: String[]) {
  const usersHighestRole = getUsersHighestRole(user_roles);

  if (required_roles.includes(usersHighestRole.name)) return true;
  return false;
}
